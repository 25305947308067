/**
 * @description 导出网络配置
 */

////----------------------------------------------------------》》》》开发环境接口访问配置《《《《------------------------------------------------------------------------------////
/**
 * 开发环境 接口请求地址
 * @type {{
 * baseURL: string,     业务访问接口
 * webscoket: string,   即时通讯连接地址
 * cloudUrl: string,    云存储上传下载
 * aiPictureUrl: string AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 */

// const requestAddrGather = {
//   baseURL: 'http://192.168.40.114:59000',
//   cloudUrl: 'http://192.168.40.114:59601',
//   webscoket: 'ws://192.168.40.203:9600',
//   aiPictureUrl: 'http://192.168.40.203:9600',
//   wsUrl:'wss://192.168.40.114:53111/room1',
//   iceUrl:'stun:106.12.254.111:3478',
// }

////----------------------------------------------------------》000》》》测试环境接口访问配置《《《《-------------------------------------------------------------------------------////
/**
 * 测试环境 接口请求地址
 * @type {{
 * baseURL: string,      业务访问接口
 * webscoket: string,    即时通讯连接地址
 * cloudUrl: string,     云存储上传下载
 * aiPictureUrl: string  AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 */
// const requestAddrGather = {
//   baseURL: 'https://testshccig.beidouenv.com',
//   cloudUrl: 'https://testshccig.beidouenv.com',
//   webscoket: 'wss://testshccig.beidouenv.com/wss/',
//   aiPictureUrl: 'https://testshccig.beidouenv.com',
// //   iceUrl:'stun:106.12.254.111:3478',
//   // iceUrl:'stun:1.82.253.175:3478',
// //   wsUrl:'wss://dsj.beidouenv.com/room1'
// iceUrl:'stun:1.82.253.175:3478',
// wsUrl:'wss://www.shxcoaleco.com/room1'
//   //   wsUrl:'wss://shccig.beidouenv.com/room1'
// //   wsUrl:'ws://1.82.253.175:53111/room2'
// // wsUrl:'ws://192.168.40.114:57695/room1'
// }


////-------------------------------------------------------》》》》开发测试公共接口访问配置环境《《《《---------------------------------------------------------------------------////
/**
 * 开发测试公用环境： arcgis api和遥感处理工具
 * @type {{
 * uploadFileUrl: string,   云处理工具访问接口
 * toolAddressUrl: string,  天地图底图访问地址
 * tiandituUrl: string,     天地图底图图层服务负载
 * subDomainsUrl: string[]  arcgis工具处理完成之后下载地址
 * }}
 *
 */

// const publicAddrGather = {
//   toolAddressUrl: 'https://portal.shaanxieco.com',
//   tiandituUrl: 'https://{subDomain}.tianditu.gov.cn',
//   subDomainsUrl: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
//   uploadFileUrl: 'https://testshccig.beidouenv.com',
//   DTSServerUrl:'dts.beidouenv.com:20488'
// }

////----------------------------------------------------------》》》》正式环境《《《《------------------------------------------------------------------------------////
/**
 *
 * 正式环境 接口访问地址
 *
 * @type {{
 * baseURL: string,      业务访问接口
 * webscoket: string,    即时通讯连接地址
 * cloudUrl: string,     云存储上传下载
 * aiPictureUrl: string  AI检测  一张图  webscoket已读消息  一张图下载
 * }}
 *
 * @type {{
 * uploadFileUrl: string,   云处理工具访问接口
 * toolAddressUrl: string,  天地图底图访问地址
 * tiandituUrl: string,     天地图底图图层服务负载
 * subDomainsUrl: string[]  arcgis工具处理完成之后下载地址
 * }}
 */
const requestAddrGather = {
  baseURL: 'https://www.shxcoaleco.com',
  webscoket: 'wss://www.shxcoaleco.com/wss/',
  cloudUrl: 'https://www.shxcoaleco.com',
  aiPictureUrl: 'https://www.shxcoaleco.com',
  iceUrl:'stun:1.82.253.175:3478',
  wsUrl:'wss://www.shxcoaleco.com/room1'
}

const publicAddrGather = {
  toolAddressUrl: 'https://portal.shaanxieco.com',
  tiandituUrl: 'https://{subDomain}.tianditu.gov.cn',
  subDomainsUrl: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
  uploadFileUrl: 'https://www.shxcoaleco.com',
  DTSServerUrl:'dts.beidouenv.com:20488'
}


module.exports = {
  // ...requestAddrGather,
  // ...publicAddrGather,
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  webscoket: `${process.env.VUE_APP_WEB_SOCKET_URL}`,
  cloudUrl: `${process.env.VUE_APP_CLOUD_URL}`,
  aiPictureUrl: `${process.env.VUE_APP_AI_PICTURE_URL}`,
  iceUrl:`${process.env.VUE_APP_ICE_URL}`,
  wsUrl:`${process.env.VUE_APP_WS_URL}`,

  toolAddressUrl: `${process.env.VUE_APP_TOOL_ADDRESS_URL}`,
  tiandituUrl: `${process.env.VUE_APP_SKY_URL}`,
  subDomainsUrl: `${process.env.VUE_APP_SUB_DOMAINS_URL}`,
  uploadFileUrl: `${process.env.VUE_APP_UP_LOAD_FILE_URL}`,

  // 数据请求的接收方式
  contentType: 'application/json;charset=UTF-8',
  // 数据请求的最大时间 1个小时
  requestTimeout: 3600000,
  successCode: [200, 0, '200', '0'],
  statusName: 'code',
  messageName: 'msg'
}
