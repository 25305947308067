import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/bd/layouts";
import { publicPath, routerMode } from "@/config";

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    meta: {
      hidden: true,
      title: "登录",
    },
  },
  {
    path: "/guidePage",
    name: "GuidePage",
    component: () => import("@/views/home"),
    meta: {
      hidden: true,
      title: "首页",
    },
  },
  {
    path: "/newsMore",
    name: "NewsMore",
    component: () => import("@/views/home/NewsMore"),
    meta: {
      hidden: true,
      title: "更多新闻",
    },
  },
  {
    path: "/homeNewsMoreDetail2",
    name: "HomeNewsMoreDetail2",
    component: () => import("@/views/home/newsMoreDetail2"),
    meta: {
      hidden: true,
      title: "新闻详情",
      dynamicNewTab: true,
    },
  },
  {
    path: "/homeNewPlanMap",
    name: "homeNewPlanMap",
    component: () => import("@/views/home/newPlanMap"),
    meta: {
      hidden: true,
      title: "地图详情",
    },
  },
  {
    path: "/bigDataVisualization",
    name: "BigDataVisualization",
    component: () => import("@/views/pages/visualization/bigMap"),
    meta: {
      hidden: true,
      title: "大屏",
    },
  },
  {
    path: "/pdfLoading",
    name: "pdfLoading",
    component: () => import("@/views/pages/projectMines/components/pdfLoading"),
    meta: {
      hidden: true,
      title: "PDF预览页",
    },
  },
  //   {
  //             path: 'organization',
  //             name: 'Organization',
  //             component: () => import('@/views/pages/sysManage/systemSet/organization'),
  //             meta: {
  //                 title: '组织架构管理',
  //                 icon: 'contrast-drop-line'
  //             }
  //         },
  // {
  //   path: '/LargeDataScreen',
  //   name: 'LargeDataScreen',
  //   component: () =>import('@/views/pages/largeScreen/index'),
  //   meta: {
  //       hidden: true,
  //       title: '大屏展示',
  //       }
  //   },
  {
    path: "/LargeDataScreen",
    name: "LargeDataScreen",
    component: () => import("@/views/pages/largeScreenNew/index"),
    meta: {
      hidden: true,
      title: "大屏展示",
    },
  },
  // {
  //   path: '/dynamicMonitor',
  //   name: 'DynamicMonitor',
  //   component: () =>import('@/views/pages/space/dynamicMonitor/index'),
  //   meta: {
  //       hidden: true,
  //       title: '大屏展示',
  //       }
  //   },
  {
    path: "/expertRegistration",
    name: "ExpertRegistration",
    component: () => import("@/views/expert/expertRegistration"),
    meta: {
      hidden: true,
      title: "expertRegistration",
    },
  },
  {
    path: "/Screen",
    name: "Screen",
    component: () => import("@/views/pages/screen/index"),
    meta: {
      hidden: true,
      title: "大屏展示",
    },
  },
  {
    path: "/403",
    name: "403",
    component: () => import("@/views/403"),
    meta: {
      hidden: true,
      title: "403",
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
    meta: {
      hidden: true,
      title: "404",
    },
  },
];

export const asyncRoutes = [
  {
    path: "/",
    name: "Root",
    component: Layout,
    meta: {
      title: "首页",
      icon: "home-2-line",
      // breadcrumbHidden: true,
      // hidden: true,
      levelHidden: true,
    },
    children: [
      {
        path: "index",
        name: "Index",
        component: () => import("@/views/home"),
        meta: {
          title: "首页",
          icon: "home-2-line",
          noClosable: true,
        },
      },
      {
        path: "newsMore",
        name: "NewsMore",
        component: () => import("@/views/home/NewsMore"),
        meta: {
          hidden: true,
          title: "更多新闻",
        },
      },
    ],
  },

  {
    path: "/projectSupervision",
    name: "ProjectSupervision",
    component: Layout,
    redirect: "/projectSupervision/index",
    meta: {
      title: "项目监管",
      icon: "database-line",
    },
    children: [
      //   {
      //     path: 'projectSupervision',
      //     name: 'ProjectSupervision',
      //     component: () => import('@/views/pages/projectSupervision/ecological/index'),
      //     meta: {
      //       title: '矿山生态修复',
      //       icon: 'remote-control-line'
      //     }
      //   },
      {
        path: "projectSupervisionNew",
        name: "ProjectSupervisionNew",
        component: () =>
          import("@/views/pages/projectSupervision/ecologicalNew/index"),
        meta: {
          title: "矿山生态修复",
          icon: "remote-control-line",
        },
      },
      {
        path: "landscape",
        name: "Landscape",
        component: () =>
          import("@/views/pages/projectSupervision/landscape/index"),
        meta: {
          title: "山水林田湖草沙",
          icon: "remote-control-line",
        },
      },
      {
        path: "universe",
        name: "Universe",
        component: () =>
          import("@/views/pages/projectSupervision/universe/index"),
        meta: {
          title: "全域土地综合整治",
          icon: "remote-control-line",
        },
      },
      {
        path: "landReclamation",
        name: "landReclamation",
        component: () =>
          import("@/views/pages/projectSupervision/landReclamation/index"),
        meta: {
          title: "土地复垦",
          icon: "remote-control-line",
        },
      },
    ],
  },
  {
    path: "/spaceTimeMoni",
    name: "spaceTimeMoni",
    component: Layout,
    redirect: "/spaceTimeMoni/DynamicMonPage/zfjc",
    meta: {
      title: "时空监测",
      icon: "database-line",
    },
    children: [
      {
        path: "DynamicMonPage/zfjc",
        name: "zfjc",
        component: () => import("@/views/pages/spaceTimeMoni/DynamicMonPage"),
        meta: {
          title: "市县监测",
          icon: "remote-control-line",
        },
      },
      {
        path: "DynamicMonPage/ksjc",
        name: "ksjc",
        component: () => import("@/views/pages/spaceTimeMoni/DynamicMonPage"),
        meta: {
          title: "矿山监测",
          icon: "remote-control-line",
        },
      },
    ],
  },
  {
    path: "/spaceTimeMonis",
    name: "spaceTimeMonis",
    component: Layout,
    redirect: "/spaceTimeMonis/Monitoring/qyjc",
    meta: {
      title: "遥感监测",
      icon: "database-line",
    },
    children: [
      {
        path: "Monitoring/qyjc",
        name: "qyjc",
        component: () => import("@/views/pages/spaceTimeMoni/Monitoring"),
        meta: {
          title: "市县监测",
          icon: "remote-control-line",
        },
      },
      {
        path: "Monitoring/dzzh",
        name: "dzzh",
        component: () => import("@/views/pages/spaceTimeMoni/Monitoring"),
        meta: {
          title: "地质灾害",
          icon: "remote-control-line",
        },
      },
      {
        path: "Monitoring/dxdm",
        name: "dxdm",
        component: () => import("@/views/pages/spaceTimeMoni/Monitoring"),
        meta: {
          title: "地形景观",
          icon: "remote-control-line",
        },
      },
      {
        path: "Monitoring/sthj",
        name: "sthj",
        component: () => import("@/views/pages/spaceTimeMoni/Monitoring"),
        meta: {
          title: "水土环境",
          icon: "remote-control-line",
        },
      },
      {
        path: "Monitoring/tdsh",
        name: "tdsh",
        component: () => import("@/views/pages/spaceTimeMoni/Monitoring"),
        meta: {
          title: "损毁复垦",
          icon: "remote-control-line",
        },
      },
    ],
  },
  // {
  //   path: '/spaceTimeMonitor',
  //   name: 'spaceTimeMonitor',
  //   component: Layout,
  //   redirect: '/spaceTimeMonitor/CandCMonitor',
  //   meta: {
  //     title: '动态监测',
  //     icon: 'cloud-line'
  //   },
  //   children: [
  //     {
  //       path: 'CandCMonitor',
  //       name: 'CandCMonitor',
  //       component: () => import('@/views/pages/spaceTimeMonitor/CandCMonitor'),
  //       meta: {
  //         title: '市县监测',
  //         icon: 'file-cloud-line'
  //       }
  //     },
  //     {
  //       path: 'ProjectMonitor',
  //       name: 'ProjectMonitor',
  //       component: () => import('@/views/pages/spaceTimeMonitor/ProjectMonitor'),
  //       meta: {
  //         title: '项目监测',
  //         icon: 'printer-cloud-line'
  //       }
  //     }
  //   ]
  // },
  {
    path: "/spatial",
    name: "Spatial",
    component: Layout,
    redirect: "/spatial/remote",
    meta: {
      title: "空间数据",
      icon: "database-line",
    },
    children: [
      {
        path: "remoteStatistics",
        name: "RemoteStatistics",
        component: () => import("@/views/pages/spatial/remoteStatistics"),
        meta: {
          title: "遥感统筹",
          icon: "remote-control-line",
        },
      },
      {
        path: "dataSearching",
        name: "DataManager",
        component: () => import("@/views/pages/spatial/dataSearching"),
        meta: {
          title: "数据检索",
          icon: "file-search-line",
        },
      },
    ],
  },
  {
    path: "/ai",
    name: "Ai",
    component: Layout,
    redirect: "/ai/aiMonitor",
    meta: {
      title: "AI",
      icon: "airplay-fill",
    },
    children: [
      {
        path: "aiMonitor",
        name: "AiMonitor",
        component: () => import("@/views/pages/ai/aiMonitor"),
        meta: {
          title: "Ai监测",
          icon: "airplay-line",
        },
      },
    ],
  },
  // {
  //   path: '/thePicture',
  //   name: 'thePicture',
  //   component: Layout,
  //   redirect: '/thePicture/DataResources/',
  //   meta: {
  //     title: '一张图',
  //     icon: 'airplay-fill'
  //   },
  //   children: [{
  //     path: '/thePicture/DataResources',
  //     name: 'DataResources',
  //     menuName: '数据资源',
  //     meta: {
  //       title: '数据资源',
  //     },
  //     component: () => import('@/views/pages/thePicture/DataResources/index'),
  //   },
  //     {
  //       path: '/thePicture/spatialAnalysis',
  //       name: 'spatialAnalysis',
  //       menuName: '空间分析',
  //       meta: {
  //         title: '空间分析',
  //         requiresAuth: true
  //       },
  //       children:[{
  //           path: '/thePicture/spatialAna_djfx',
  //           name: 'spatialAna_djfx',
  //           menuName: '叠加分析',
  //           meta: {
  //             title: '叠加分析',
  //           },
  //           component: () => import('@/views/pages/thePicture/spatialAnalysis/index'),
  //         },{
  //           path: '/thePicture/spatialAna_tqfx',
  //           name: 'spatialAna_tqfx',
  //           menuName: '提取分析',
  //           meta: {
  //             title: '提取分析',
  //           },
  //           component: () => import('@/views/pages/thePicture/spatialAnalysis/index'),
  //         },{
  //           path: '/thePicture/spatialAna_lyfx',
  //           name: 'spatialAna_lyfx',
  //           menuName: '邻域分析',
  //           meta: {
  //             title: '邻域分析',
  //           },
  //           component: () => import('@/views/pages/thePicture/spatialAnalysis/index'),
  //         },{
  //           path: '/thePicture/spatialAna_hcfx',
  //           name: 'spatialAna_hcfx',
  //           menuName: '缓冲区分析',
  //           meta: {
  //             title: '缓冲区分析',
  //           },
  //           component: () => import('@/views/pages/thePicture/spatialAnalysis/index'),
  //         }]
  //     },
  //
  //     {
  //       component: Layout,
  //       redirect: '/thePicture/DataResources/',
  //       path: '/thePicture/basic',
  //       name: 'Statistics',
  //       menuName: '统计分析',
  //       meta: {
  //         title: '统计分析',
  //         requiresAuth: true
  //       },
  //       children:[{
  //         path: '/thePicture/gstatistics_basics',
  //         name: 'gstatistics_basics',
  //         menuName: '基础统计',
  //         meta: {
  //           title: '基础统计',
  //         },
  //         component: () => import('@/views/pages/thePicture/Statistics/index'),
  //       },{
  //         path: '/thePicture/geodisaster_analysis',
  //         name: 'geodisaster_analysis',
  //         menuName: '时序分析',
  //         meta: {
  //           title: '时序分析',
  //         },
  //         component: () => import('@/views/pages/thePicture/Statistics/index'),
  //       }]
  //     },
  //     {
  //       path: '/thePicture/ThematicOutput',
  //       name: 'ThematicOutput',
  //       menuName: '专题输出',
  //       meta: {
  //         title: '专题输出',
  //         requiresAuth: true
  //       },
  //       component: () => import('@/views/pages/thePicture/ThematicOutput/index'),
  //     }]
  // },
  {
    path: "/cloud",
    name: "Cloud",
    component: Layout,
    redirect: "/cloud/processing",
    meta: {
      title: "云服务",
      icon: "cloud-line",
    },
    children: [
      {
        path: "cloudProcessing",
        name: "CloudProcessing",
        component: () => import("@/views/pages/cloud/cloudProcessing"),
        meta: {
          title: "云处理",
          icon: "file-cloud-line",
        },
      },
      {
        path: "cloudStorage",
        name: "CloudStorage",
        component: () => import("@/views/pages/cloud/cloudStorage"),
        meta: {
          title: "云存储",
          icon: "printer-cloud-line",
        },
      },
    ],
  },
  {
    path: "/project",
    name: "Project",
    component: Layout,
    redirect: "/project/reviewed",
    meta: {
      title: "项目审批",
      icon: "cloud-line",
    },
    children: [
      {
        path: "reviewed",
        name: "Reviewed",
        component: () => import("@/views/pages/projectApproval/Reviewed"),
        meta: {
          title: "待审核",
          icon: "file-cloud-line",
        },
      },
      {
        path: "approvalRecord",
        name: "ApprovalRecord",
        component: () => import("@/views/pages/projectApproval/ApprovalRecord"),
        meta: {
          title: "审批记录",
          icon: "printer-cloud-line",
        },
      },
    ],
  },
  {
    path: "/sysManage",
    name: "SysManage",
    component: Layout,
    redirect: "/sysManage/mineManage",
    alwaysShow: true,
    meta: {
      title: "系统管理",
      icon: "computer-line",
    },
    children: [
      {
        path: "homeMaintain",
        name: "HomeMaintain",
        component: () =>
          import("@/views/pages/sysManage/maintain/homeMaintain"),
        meta: {
          title: "首页维护",
          icon: "mine",
        },
      },
      {
        path: "newsMoreDetail2",
        name: "NewsMoreDetail2",
        component: () => import("@/views/home/newsMoreDetail2"),
        meta: {
          hidden: true,
          title: "新闻详情",
          activeMenu: "/sysManage/homeMaintain",
        },
      },
      {
        path: "newPlanMap",
        name: "NewPlanMap",
        component: () => import("@/views/home/newPlanMap"),
        meta: {
          hidden: true,
          title: "地图详情",
          activeMenu: "/sysManage/homeMaintain",
        },
      },
      {
        path: "detail",
        name: "Detail",
        component: () => import("@/views/pages/sysManage/maintain/detail"),
        meta: {
          hidden: true,
          title: "编辑",
          activeMenu: "/sysManage/homeMaintain",
          dynamicNewTab: true,
        },
      },
      {
        path: "homeAdd",
        name: "HomeAdd",
        component: () => import("@/views/pages/sysManage/maintain/homeAdd"),
        meta: {
          hidden: true,
          title: "新增页",
          activeMenu: "/homeManage",
          dynamicNewTab: true,
        },
      },
      {
        path: "mineManage",
        name: "MineManage",
        component: () => import("@/views/pages/sysManage/mine/mineManage"),
        meta: {
          title: "矿山管理",
          icon: "mine",
        },
      },
      {
        path: "flowManage",
        name: "FlowManage",
        redirect: "/sysManage/flowManage/recover",
        meta: {
          title: "流程管理",
          icon: "flow-chart",
        },
        children: [
          {
            path: "recover",
            name: "Recover",
            component: () => import("@/views/pages/sysManage/flow/recover"),
            meta: {
              title: "治理恢复",
              icon: "device-recover-line",
            },
          },
          {
            path: "zoology",
            name: "Zoology",
            component: () => import("@/views/pages/sysManage/flow/zoology"),
            meta: {
              title: "生态修复",
              icon: "contrast-drop-line",
            },
          },
        ],
      },
      // {
      //   path: '/module',
      //   component: Layout,
      //   redirect: '/module/icon/awesomeIcon',
      //   name: 'Module',
      //   alwaysShow: true,
      //   meta: {
      //     title: '组件管理',
      //     icon: 'sound-module-line'
      //   },
      //   children: [
      //     {
      //       path: 'icon',
      //       redirect: '',
      //       name: 'Icon',
      //       meta: {
      //         title: '字体图标',
      //         icon: 'remixicon-line'
      //       },
      //       children: [
      //         {
      //           path: 'awesomeIcon',
      //           name: 'AwesomeIcon',
      //           component: () => import('@/views/pages/module/icon/index'),
      //           meta: {
      //             title: '常规图标',
      //             icon: 'creative-commons-zero-line'
      //           }
      //         },
      //         {
      //           path: 'remixIcon',
      //           name: 'RemixIcon',
      //           component: () => import('@/views/pages/module/icon/remixIcon'),
      //           meta: {
      //             title: '小清新图标',
      //             icon: 'picture-in-picture-2-line'
      //           }
      //         }
      //       ]
      //     },
      //     {
      //       path: 'map',
      //       redirect: 'noRedirect',
      //       name: 'map',
      //       meta: {
      //         title: '地图展示',
      //         icon: 'road-map-line'
      //       },
      //       children: [
      //         {
      //           path: 'Map2D',
      //           name: 'Map2D',
      //           component: () => import('@/views/pages/module/arcgis/map2D'),
      //           meta: {
      //             title: '二维地图',
      //             icon: 'map-line'
      //           }
      //         },
      //         {
      //           path: 'Map3D',
      //           name: 'Map3D',
      //           component: () => import('@/views/pages/module/arcgis/map3D'),
      //           meta: {
      //             title: '三维地图',
      //             icon: 'map-2-line'
      //           }
      //         }
      //       ]
      //     },
      //     {
      //       path: 'drawer',
      //       name: 'Drawer',
      //       redirect: '',
      //       meta: {
      //         title: '浮动模块',
      //         icon: 'windows-line'
      //       },
      //       children: [
      //         {
      //           path: 'elDrawer',
      //           name: 'ElDrawer',
      //           component: () => import('@/views/pages/module/drawer/elDrawer'),
      //           meta: {
      //             title: '抽屉组件',
      //             icon: 'archive-drawer-line'
      //           }
      //         }
      //       ]
      //     }
      //   ]
      // },
      {
        path: "/systemSet",
        name: "SystemSet",
        // redirect: '/sysManage/systemSet/user',
        meta: {
          title: "系统设置",
          icon: "flow-chart",
        },
        children: [
          {
            path: "tagManage",
            name: "TagManage",
            component: () =>
              import("@/views/pages/sysManage/systemSet/tagManage"),
            meta: {
              title: "标签管理",
              icon: "price-tag-3-line",
              // noColumn: true
            },
          },
          {
            path: "dictionariesManage",
            name: "DictionariesManage",
            component: () =>
              import("@/views/pages/sysManage/systemSet/dictionariesManage"),
            meta: {
              title: "字典管理",
              icon: "book-line",
            },
          },
          {
            path: "roleManage",
            name: "roleManage",
            component: () =>
              import("@/views/pages/sysManage/systemSet/roleManage"),
            meta: {
              title: "角色管理",
              icon: "contrast-drop-line",
            },
          },
          {
            path: "menu",
            name: "Menu",
            component: () => import("@/views/pages/sysManage/systemSet/menu"),
            meta: {
              title: "菜单管理",
              icon: "contrast-drop-line",
            },
          },
          {
            path: "userList",
            name: "UserList",
            component: () =>
              import("@/views/pages/sysManage/systemSet/userList"),
            meta: {
              title: "用户列表",
              icon: "contrast-drop-line",
            },
          },
          {
            path: "personalCenter",
            name: "PersonalCenter",
            component: () =>
              import("@/views/pages/sysManage/systemSet/personalCenter"),
            meta: {
              title: "个人中心",
              icon: "contrast-drop-line",
            },
          },
          {
            path: "logList",
            name: "LogList",
            component: () =>
              import("@/views/pages/sysManage/systemSet/logList"),
            meta: {
              title: "系统日志",
              icon: "contrast-drop-line",
            },
          },
          {
            path: "indexManagement",
            name: "IndexManagement",
            component: () =>
              import("@/views/pages/sysManage/systemSet/indexManagement"),
            meta: {
              title: "设备指标管理",
              icon: "contrast-drop-line",
            },
          },
          {
            path: "typeManagement",
            name: "TypeManagement",
            component: () =>
              import("@/views/pages/sysManage/systemSet/typeManagement"),
            meta: {
              title: "设备类型管理",
              icon: "contrast-drop-line",
            },
          },
          {
            path: "minesStatistics",
            name: "MinesStatistics",
            component: () =>
              import("@/views/pages/sysManage/systemSet/minesStatistics"),
            meta: {
              title: "矿山统计维护",
              icon: "contrast-drop-line",
            },
          },
        ],
      },
      // {
      //   path: '/Demo_vue_cropper',
      //   name: 'Demo_vue_cropper',
      //   component: Layout,
      //   redirect: '/module/demo/index',
      //   alwaysShow: true,
      //   meta: {
      //     title: '封装demo',
      //     icon: 'sound-module-line'
      //   },
      //   children: [
      //     {
      //       path: 'demo_vue_cropper',
      //       name: 'Demo_vue_cropper',
      //       component: () => import('@/views/pages/module/demo/cropper_index.vue'),
      //       meta: {
      //         title: '上传图片裁剪',
      //         icon: 'creative-commons-zero-line'
      //       }
      //     },
      //     {
      //       path: 'demo_vue_uploadFile',
      //       name: 'Demo_vue_uploadFile',
      //       component: () => import('@/views/pages/module/demo/uploadFile_index.vue'),
      //       meta: {
      //         title: '上传文件',
      //         icon: 'creative-commons-zero-line'
      //       }
      //     },
      //       {
      //         path: 'demo_vue_number',
      //         name: 'Demo_vue_number',
      //         component: () => import('@/views/pages/module/demo/number_index.vue'),
      //         meta: {
      //           title: '科技感数字',
      //           icon: 'creative-commons-zero-line'
      //         }
      //     }
      //   ]
      // }
    ],
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

const router = createRouter();

function fatteningRoutes(routes) {
  return routes.flatMap((route) => {
    return route.children ? fatteningRoutes(route.children) : route;
  });
}

export function resetRouter(routes = constantRoutes) {
  routes.forEach((route) => {
    if (route.children) {
      route.children = fatteningRoutes(route.children);
    }
  });
  router.matcher = createRouter(routes).matcher;
}

function createRouter(routes = constantRoutes) {
  return new VueRouter({
    base: publicPath,
    mode: routerMode,
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: routes,
  });
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
