<template>
    <div id="mapview"></div>
</template>

<script>
import { mapActions } from 'vuex'
import Map from '@arcgis/core/Map'
import MapView from '@arcgis/core/views/MapView'
import Basemap from '@arcgis/core/Basemap'
// import TileLayer from '@arcgis/core/layers/TileLayer'
import WebTileLayer from '@arcgis/core/layers/WebTileLayer'
import {subDomainsUrl, tiandituUrl} from "@/config";
import esriConfig from '@arcgis/core/config'

export default {
  name: 'MapView',
  mounted () {
    this.createMapView()
  },
  methods: {
    ...mapActions({
      changeView: 'arcgis/changeView'
    }),
    createMapView () {
      // const chinabasemap = new TileLayer({
      //   url: 'http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer' // 中国彩色底图
      // })
      esriConfig.apiKey = "AAPK3c95aa1ef4534bc586a1e2b3331b24c2ggia2FYcSV1QOijNvW6gRv2IOTZ_ZZXRRZ8cDZgq9S_dGgrgcyt98ItbNLNexYH5";
      // let token = "56cfa4d5e3d1431b96ff2ae00c9b0281"; //天地图token
      let token = "6e1e9ac0ebd562e9bdf36dcc14f2ea60"; //天地图token

      const domainIndex = Math.floor(Math.random() * 8);
      const domain = "t" + domainIndex;

      let tiledLayer12 = new WebTileLayer({
        urlTemplate: "https://" + domain + ".tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={level}&TILEROW={row}&TILECOL={col}&tk=" + token,
        subDomains:subDomainsUrl,
        title: "天地图加载",
      });
      let tiledLayer13 = new WebTileLayer({
        urlTemplate: "https://" + domain + ".tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={level}&TILEROW={row}&TILECOL={col}&tk=" + token,
        subDomains:subDomainsUrl,
        title: "天地图加载",
      });

      const stamen = new Basemap({
        baseLayers: [tiledLayer12,tiledLayer13]
      })

      const map = new Map({
        basemap: stamen
      })

      const view = new MapView({
        map: map,
        container: 'mapview',
        center: [108.953939, 34.345123],
        zoom: 6.5
      })

      view.when(() => {
        this.changeView(view)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
/*二维地图组件 样式文件*/
#mapview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
</style>
