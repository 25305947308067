/**
 * @description 通用配置
 */

module.exports = {
  title: '矿山生态修复时空大数据平台',
  // 标题分隔符
  titleSeparator: ' - ',
  // 标题是否反转 ? "title - page" : "page - title"
  titleReverse: false,
  // 路由模式，可选值为 history 或 hash; 暂时不支持 history 模式
  routerMode: 'hash',
  // 路由白名单，即不经过 token 校验的路由，此类路由建议配置到与 login 页面同级
  routesWhiteList: ['/login', '/expertRegistration', '/404', '/403'],
  // 加载时显示文字
  loadingText: '正在加载中...',
  // intelligence(前端导出路由)和all(后端导出路由)
  authentication: 'all',
  // 是否开启roles字段进行角色权限控制(如果是all模式后端完全处理角色并进行json组装，可设置false不处理路由中的roles字段)
  // rolesControl: true,
  // 消息框消失时间
  messageDuration: 3000,
  // 缓存路由的最大数量
  keepAliveMaxNum: 20,
  // token名称
  tokenName: 'token',
  // token在localStorage、sessionStorage、cookie存储的key的名称
  tokenTableName: 'bd-token',
  // token失效回退到登录页时是否记录本次的路由（是否记录当前tab页）
  recordRoute: false,
  // 设置带 loading 层的请求，防止重复提交
  debounce: ['needEdit'],
  // vertical column comprehensive common布局时默认展开的菜单path，使用逗号隔开建议只展开一个
  defaultOpeneds: [
    '/sysManage/flowManage',
    '/module',
    '/systemSet',
    '/Demo_vue_cropper'
  ],
}
